<template>
  <a-switch
    v-bind="record.options"
    :checked="value === checkedValue"
    @change="change"
    @click="click"
  />
</template>

<script>
import formEleMixin from './form-ele-mixin'
export default {
  name: 'AxSwitch',
  mixins: [formEleMixin],
  computed: {
    checkedValue () {
      const { checkedValue } = this.record.options
      return this.valueForEmpty(checkedValue, true)
    },
    unCheckedValue () {
      const { unCheckedValue } = this.record.options
      return this.valueForEmpty(unCheckedValue, false)
    }
  },
  methods: {
    /**
     * 变化时回调函数
     * @param value
     */
    change (value) {
      this.$emit('change', value ? this.checkedValue : this.unCheckedValue, this.record.model)
    },
    /**
     * 点击时回调函数
     * @param value
     */
    click (value) {
      this.$emit('click', value ? this.checkedValue : this.unCheckedValue, this.record.model)
    }
  }
}
</script>
